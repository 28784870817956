<template>
  <div class="container">
    <div class="logo" @click.stop="goToPage('Home')"></div>
    <section class="pillow">
      <div class="title">LOGIN</div>
      <div class="form">
        <input type="text" v-model="email" id="email" placeholder="Email Address" required @keypress.enter="doLogin" />
        <input type="password" v-model="password" id="password" placeholder="Password" required @keypress.enter="doLogin" />
        <p v-show="show_error">{{ error.text }}</p>
      </div>
      <div class="action">
        <v-btn
          x-large
          dark
          outlined
          @click.stop="doLogin"
        >
          LOGIN
        </v-btn>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data: () => ({
    email: '',
    password: '',
    error: {
      show: false,
      text: ''
    }
  }),
  methods: {
    doLogin: function () {
      console.log('Logging in!')
      window.axios.post('/login', {
        email: this.email,
        password: this.password
      })
        .then((response) => {
          if (response.data.success) {
            console.log('Success!')
            if (response.data.access_token) {
              this.$store.dispatch('authenticateUser', {
                access_token: response.data.access_token,
                user: response.data.user
              })
            }
          } else {
            console.error('Failed to login!')
            console.error(response)
            this.error.show = true
            this.error.text = response.data.errors
          }
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },
  computed: {
    show_error: function () {
      return this.error.show
    }
  }
}
</script>

<style scoped>
.container {
  height: auto;
  min-height: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
div.logo {
  background-image: url(/images/logo.png);
  background-size: 80px 80px;
  width: 100%;
  height: 80px;
  z-index: 10;
  background-position: center;
}
section.pillow {
  width: 40%;
  background-color: rgba(0,0,0,.5);
  padding: 4px 4px 30px 4px;
  border-radius: 15px;
  margin: 40px auto 0 auto;
}
section.pillow div.title {
  background-color: rgba(255,255,255,.25);
  font-size: 2em;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  border-radius: 12px 12px 0 0;
  width: 100%;
  margin: 0 auto 20px auto;
}
section.pillow div.form input {
  width: 80%;
  display: block;
  padding: 8px 10px;
  background-color: white;
  color: black;
  margin: 20px auto;
  border-radius: 4px;
  font-size: 1.5em;
}
section.pillow div.form p {
  width: 80%;
  margin: 20px auto;
  color: red;
  background-color: rgba(255,125,125,1);
  color: white;
  border: solid 3px red;
  text-align: center;
}
section.pillow div.action {
  margin-top: 30px;
  text-align: center;
}
@media screen and (max-width: 1120px){
  section.pillow {
    width: 50%;
  }
}
@media screen and (max-width: 820px){
  section.pillow {
    width: 60%;
  }
}
@media screen and (max-width: 580px){
  section.pillow {
    width: 75%;
  }
}
@media screen and (max-width: 480px){
  section.pillow {
    width: 90%;
  }
  section.pillow div.form input {
    width: 90%;
  }
  section.pillow div.form p {
    width: 90%;
  }
}
</style>
